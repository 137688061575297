<div id="notfound">
  <div class="notfound">
    <div class="notfound-404">
      <h3>Oops! Page not found</h3>
      <h1 class="digits-contianer">
        <span class="digits">4</span><span class="digits">0</span
        ><span class="digits">4</span>
      </h1>
    </div>
    <h2>we are sorry, but the page you requested was not found</h2>
    <br />
    <div class="button-container">
      <button class="home-btn" (click)="homeBtn()">HOME</button>
    </div>
  </div>
</div>
