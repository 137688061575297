<div class="toolbar">
  <app-toolbar></app-toolbar>
</div>
<div class="container">
  <div class="bigtext-container">
    <div class="side-text">
      <p style="margin-bottom: 0px; padding-bottom: 0px">Your hired gun</p>
      <p style="margin-top: 0px; padding-bottom: 0px; margin-left: 48px">
        in storytelling
      </p>
    </div>
    <img class="bigtext" src="../../../assets/Big Text.svg" />
  </div>

  <div class="teeth-containerB">
    <div class="teeth-container">
      <img
        class="bigTeethForeground"
        src="../../../assets/Foreground Teeth.png"
      />
      <img class="bigTeeth" src="../../../assets/BigTeeth.png" />
    </div>
  </div>

  <div class="bottomtext-containerb">
    <div class="bottom-text-container">
      <p class="bottom-text">Podcast Creation</p>
      <p class="bottom-text">Invisible Branding</p>
      <p class="bottom-text">Hybrid Events and Festivals</p>
      <p class="bottom-text">Speaker Coaching</p>
      <p class="bottom-text">Facilitation Training</p>
      <p class="bottom-text">Podcast Training</p>
      <p class="between-prod-text">BETWEEN PRODUCTIONS</p>
      <div class="divider">
        <hr class="right" />
        <hr />
      </div>
    </div>
  </div>
</div>
