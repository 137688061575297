<div class="toolbar">
  <app-toolbarwhite></app-toolbarwhite>
</div>
<div class="container">
  <div class="contact">
    <h1>Contact</h1>
    <p class="contact-numbers">Thabo: 071 491 0700</p>
    <b></b>
    <p class="contact-numbers">Bianca: 082 926 1222</p>
    <b></b>
    <p class="email">
      <a href="mailto:thegoodpeople@betweenproductions.co.za"
        >Send us an email</a
      >
    </p>
  </div>
  <div class="social-container">
    <a class="social" href="https://www.linkedin.com/company/54077820/"
      >linkedin</a
    >
    <a class="social" href="https://www.facebook.com/betweenproductions/"
      >facebook</a
    >
    <a class="social" href="https://www.instagram.com/betweenproductions/"
      >instagram</a
    >
  </div>
  <div class="email-container">
    <div class="address-container">
      <p class="address">Johannesburg</p>
      <p class="address">South Africa</p>
    </div>
  </div>
</div>

<footer class="footer">
  <div class="wild-grumble">crafted by</div>
  <a class="wild-grumble" target="_blank" href="https://www.wildgrumble.com"
    >WildGrumble Studios</a
  >
</footer>
