import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {
  packages: string[] = [];
  img1: string;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.img1 = '../../../assets/1.png';
    this.packages.push(this.img1);
  }

  checkLargePrice() {
    console.log('test');
    this.router.navigateByUrl('large');
  }
}
