<div class="toolbar">
  <app-toolbar-shows></app-toolbar-shows>
</div>

<div class="left-container">
  <div *ngIf="!replaceSpotify; else replaced" class="left-top-container">
    <iframe
      (load)="myLoadEvent()"
      class="iframe"
      [src]="safeResourceUrl"
      frameborder="0"
      allowtransparency="true"
      allow="encrypted-media"
    ></iframe>

    <div *ngIf="!loading; else loadingSpotify"></div>
  </div>
  <ng-template #replaced>
    <div class="iframe">
      <img
        class="replaced-image"
        [src]="replacedImg"
        alt=""
        (load)="imageLoaded()"
      />
    </div>
  </ng-template>
  <ng-template #loadingSpotify>
    <div class="loader"></div>
  </ng-template>

  <div class="left-bottom-container">
    <div class="shows-meta-container">
      <h1 class="shows-meta-text">{{ showTitle }}</h1>
      <p class="shows-meta-description-text">
        {{ showDescription }}
      </p>
    </div>
  </div>
</div>

<div class="right-container">
  <div class="shows-container">
    <div class="work-text">Work</div>
    <mat-grid-list cols="4" rowHeight="1:1">
      <mat-grid-tile class="private-client-images">
        <img
          (click)="setCollapse()"
          src="../../../assets/The Forge.svg"
          alt="The Forge"
          (click)="changeChannel(1)"
          class="grid-img"
        />
      </mat-grid-tile>

      <mat-grid-tile class="private-client-images">
        <img
          (click)="setCollapse()"
          src="../../../assets/Atlantic Fellows For Racial Equity.svg"
          alt="Atlantic Fellows For Racial Equity"
          (click)="changeChannel(2)"
          class="grid-img"
      /></mat-grid-tile>
      <mat-grid-tile class="private-client-images">
        <img
          (click)="setCollapse()"
          src="../../../assets/The Nelson Mandela Foundation.svg"
          alt="The Nelson Mandela Foundation"
          (click)="changeChannel(2)"
          class="grid-img"
      /></mat-grid-tile>

      <mat-grid-tile class="private-client-images">
        <img
          (click)="setCollapse()"
          src="../../../assets/Radio New Frame.svg"
          alt="Radio New Frame"
          (click)="changeChannel(3)"
          class="grid-img"
      /></mat-grid-tile>
      <mat-grid-tile class="private-client-images">
        <img
          (click)="setCollapse()"
          src="../../../assets/The Swedish Embassy.svg"
          alt="The Swedish Embassy"
          (click)="changeChannel(4)"
          class="grid-img"
      /></mat-grid-tile>
      <mat-grid-tile class="private-client-images">
        <img
          (click)="setCollapse()"
          src="../../../assets/TEDx.svg"
          alt="TEDx"
          (click)="changeChannel(5)"
          class="grid-img"
      /></mat-grid-tile>
      <mat-grid-tile class="private-client-images">
        <img
          (click)="setCollapse()"
          src="../../../assets/Tricontinental.svg"
          alt="Tricontinental"
          (click)="changeChannel(6)"
          class="grid-img"
      /></mat-grid-tile>
      <mat-grid-tile class="private-client-images">
        <img
          (click)="setCollapse()"
          src="../../../assets/Radio New Frame Mixtape.svg"
          alt="Radio New Frame Mixtape"
          (click)="changeChannel(7)"
          class="grid-img"
      /></mat-grid-tile>
      <mat-grid-tile class="private-client-images">
        <img
          (click)="setCollapse()"
          src="../../../assets/The French Embassy.svg"
          alt="French Embassy"
          (click)="changeChannel(8)"
          class="grid-img"
      /></mat-grid-tile>
      <mat-grid-tile class="private-client-images">
        <img
          (click)="setCollapse()"
          src="../../../assets/The 21.svg"
          alt="The 21"
          (click)="changeChannel(9)"
          class="grid-img"
      /></mat-grid-tile>
      <mat-grid-tile class="private-client-images">
        <img
          (click)="setCollapse()"
          src="../../../assets/NCCB.svg"
          alt="NCCB"
          (click)="changeChannel(10)"
          class="grid-img"
      /></mat-grid-tile>

      <mat-grid-tile class="private-client-images">
        <img
          (click)="setCollapse()"
          src="../../../assets/Human Rights Media Centre.svg"
          alt="Human Rights Media Centre"
          (click)="changeChannel(11)"
          class="grid-img"
      /></mat-grid-tile>

      <mat-grid-tile class="private-client-images">
        <img
          (click)="setCollapse()"
          src="../../../assets/Africori.svg"
          alt="Africori"
          (click)="changeChannel(12)"
          class="grid-img"
      /></mat-grid-tile>
      <mat-grid-tile class="private-client-images">
        <img
          (click)="setCollapse()"
          src="../../../assets/Sound Africa.svg"
          alt="Sound Africa"
          (click)="changeChannel(13)"
          class="grid-img"
      /></mat-grid-tile>

      <mat-grid-tile class="private-client-images">
        <img
          (click)="setCollapse()"
          src="../../../assets/Pinsent Masons.svg"
          alt="Pinsent Masons"
          (click)="changeChannel(14)"
          class="grid-img"
      /></mat-grid-tile>

      <mat-grid-tile class="private-client-images">
        <img
          (click)="setCollapse()"
          src="../../../assets/Heartveld Venison.svg"
          alt="Heartveld Venison"
          (click)="changeChannel(15)"
          class="grid-img"
      /></mat-grid-tile>

      <mat-grid-tile class="private-client-images">
        <img
          (click)="setCollapse()"
          src="../../../assets/Paradise Festival.svg"
          alt="Paradise Festival"
          (click)="changeChannel(16)"
          class="grid-img"
      /></mat-grid-tile>

      <mat-grid-tile class="private-client-images">
        <img
          (click)="setCollapse()"
          src="../../../assets/Rooiberg Resort.svg"
          alt="Paradise Festival"
          (click)="changeChannel(17)"
          class="grid-img"
      /></mat-grid-tile>
    </mat-grid-list>
  </div>
</div>

<div [ngClass]="collapse ? 'show-modal' : 'hide-modal'">
  <div class="modal-content">
    <span (click)="closeModal()" class="close">&times;</span>
    <div *ngIf="!replaceSpotify; else replaced">
      <iframe
        class="iframe-modal"
        [src]="safeResourceUrl"
        frameborder="0"
        allowtransparency="true"
        allow="encrypted-media"
      ></iframe>
    </div>
    <ng-template #replaced>
      <div class="iframe">
        <img
          (load)="myLoadEvent()"
          class="replaced-image"
          [src]="replacedImg"
          alt=""
        />
      </div>
    </ng-template>
    <div *ngIf="!loading; else loadingSpotify"></div>
    <ng-template #loadingSpotify>
      <div class="loader"></div>
    </ng-template>
    <div class="shows-meta-container-modal">
      <h1 class="shows-meta-text-modal">{{ showTitle }}</h1>
      <p class="shows-meta-description-text-modal">
        {{ showDescription }}
      </p>
    </div>
  </div>
</div>

<!-- <div class="footer">made by Wild Grumble</div> -->
