import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-largeprice',
  templateUrl: './largeprice.component.html',
  styleUrls: ['./largeprice.component.css']
})
export class LargepriceComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}
}
