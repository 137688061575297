<div class="toolbar">
  <app-toolbar></app-toolbar>
</div>
<div class="container">
  <div class="pricing">
    <img class="package" src="../../../assets/1.png" />
    <img class="package" src="../../../assets/2.png" />
    <img class="package" src="../../../assets/3.png" />
    <img class="package" src="../../../assets/4.png" />
    <img class="package" src="../../../assets/5.png" />
  </div>
  <div class="button-footer-container">
    <div class="extended-list-container">
      <button (click)="checkLargePrice()" class="extended-list-button">
        Extended Price list
      </button>
    </div>
  </div>
</div>
