import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Url } from 'url';

@Component({
  selector: 'app-shows',
  templateUrl: './shows.component.html',
  styleUrls: ['./shows.component.css'],
})
export class ShowsComponent implements OnInit {
  collapse: boolean;
  videoUrl: string;
  showTitle: string;
  showDescription: string;
  safeResourceUrl: SafeResourceUrl;
  url: Url;
  loading: boolean;
  replaceSpotify: boolean;
  replacedImg: string;

  constructor(private sanitizer: DomSanitizer) {
    this.showTitle = 'The Forge';
    this.showDescription = `The Forge, located in Braamfontein (Johannesburg, South Africa), is
    designed to facilitate the genesis, presentation, and analysis of radical ideas from
    pan-African, socialist, feminist, and other progressive perspectives. It is a space for
    the expression and discussion of ideas, for new encounters, and for the development
    of solidarities. The Good People provide streaming services, technical support and training to produce discussions/talks, cultural work (music, poetry, dance, theatre), book launches, exhibitions, film screenings, colloquiums, and workshops.
    `;
    this.collapse = false;
    this.replaceSpotify = false;
    this.loading = true;
    this.replacedImg = '../../../assets/The Forge.svg';
    this.videoUrl = 'https://www.youtube.com/embed/hh7RlYjfe7Q?start=8761';
  }

  ngOnInit(): void {
    this.safeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.videoUrl
    );
    console.warn(this.loading);
  }

  myLoadEvent(): void {
    this.loading = false;
    console.warn(this.loading);
  }

  imageLoaded() {
    this.loading = false;
    console.warn(this.loading);
  }

  setCollapse(): void {
    if (window.innerWidth <= 768) {
      if (this.collapse === false) {
        this.collapse = true;
      } else if (this.collapse) {
        this.collapse = false;
      }
      console.warn(this.collapse);
    }
  }

  changeChannel(index: number): void {
    console.log('channel index', index);
    switch (index) {
      case 1:
        this.videoUrl = 'https://www.youtube.com/embed/hh7RlYjfe7Q?start=8761';
        this.sanitizeUrl(this.videoUrl);
        this.showTitle = 'THE FORGE';
        this.showDescription = `The Forge, located in Braamfontein (Johannesburg, South Africa), is
        designed to facilitate the genesis, presentation, and analysis of radical ideas from
        pan-African, socialist, feminist, and other progressive perspectives. It is a space for
        the expression and discussion of ideas, for new encounters, and for the development
        of solidarities. The Good People provide streaming services, technical support and training to produce discussions/talks, cultural work (music, poetry, dance, theatre), book launches, exhibitions, film screenings, colloquiums, and workshops.
        `;
        this.loading = true;
        this.replaceSpotify = false;
        break;
      case 2:
        this.videoUrl =
          '//html5-player.libsyn.com/embed/destination/id/3021272/height/450/theme/custom/thumbnail/yes/direction/forward/no-cache/true/render-playlist/yes/custom-color/b4abbd/';
        this.sanitizeUrl(this.videoUrl);
        this.showTitle = 'THE ATLANTIC FELLOWS FOR RACIAL EQUITY';
        this.showDescription = `Race Beyond Borders is a podcast that exists to raise new questions about race & Blackness beyond geographical divides. The Good People take care of the podcast production, story facilitation, and technical support. `;
        this.loading = true;
        this.replaceSpotify = false;
        break;
      case 3:
        this.videoUrl =
          '//html5-player.libsyn.com/embed/destination/id/2549621/height/450/theme/custom/thumbnail/yes/direction/backward/no-cache/true/render-playlist/yes/custom-color/911313/menu/no/';
        this.sanitizeUrl(this.videoUrl);
        this.showTitle = 'RADIO NEW FRAME';
        this.showDescription = `We proudly partner with the progressive news firm New Frame. New Frame is subscribed to a custom package with Between Productions releasing 2 podcasts each week. A mixtape of political songs and stories is released each Wednesday and Radio New Frame - a news analysis podcast - is released each Friday on all major platforms. The shows are hosted by journalists and reporters at New Frame. The Good People coach the hosts and are responsible for the audiograms, final audio production, edit, mix, master, and distribution of the shows. We also aided in the set up of their studio and software training. At the end of every season, we provide an in depth data analysis of the shows’ performances.`;
        this.loading = true;
        this.replaceSpotify = false;
        break;
      case 4:
        this.videoUrl =
          'https://www.facebook.com/plugins/video.php?height=340&href=https%3A%2F%2Fwww.facebook.com%2FSwedeninSA%2Fvideos%2F430340521766527%2F&show_text=false&width=560&t=0';
        this.sanitizeUrl(this.videoUrl);
        this.showTitle = 'THE SWEDISH EMBASSY - HYBRID EVENT PRODUCTION';
        this.showDescription = `The Good People provide Hybrid Event facilitation for the PAMOJA LAUNCH in Braamfontein, Johannesburg.`;
        this.loading = true;
        this.replaceSpotify = false;
        //this.replacedImg = '../../../assets/Africori 16-9.png';
        break;
      case 5:
        this.videoUrl = 'https://www.youtube.com/embed/-ph_o7kbm70';
        this.sanitizeUrl(this.videoUrl);
        this.showTitle = 'TEDx';
        this.showDescription = `The Good People support the TEDxPretoria team with their production.`;
        this.loading = true;
        this.replaceSpotify = false;
        break;
      case 6:
        this.videoUrl = 'https://www.youtube.com/embed/MiL1L_wzvlQ';
        this.sanitizeUrl(this.videoUrl);
        this.showTitle = 'TRICONTINENTAL';
        this.showDescription = `The Tricontinental: Institute for Social Research, is a proud continuation of the heritage of the Tricontinental conference and honoured to collaborate with OSPAAAL to work towards building a world of peace and justice. The Good People provide Webinar and Streaming support.`;
        this.loading = true;
        this.replaceSpotify = false;
        //this.replacedImg = '../../../assets/Pinsent WORK.svg';
        break;

      case 7:
        this.videoUrl =
          'https://www.mixcloud.com/widget/iframe/?feed=%2FRadioNewFrame%2F';
        this.sanitizeUrl(this.videoUrl);
        this.showTitle = 'NEW FRAME MIXTAPE';
        this.showDescription = `DJ Charles Leonard releases a themed mixtape fortnightly. We support this process with editing, mixing, mastering, and publishing.`;
        this.loading = true;
        this.replaceSpotify = false;
        //this.replacedImg = '../../../assets/Pinsent WORK.svg';
        break;

      case 8:
        this.videoUrl = 'https://www.youtube.com/embed/xfWNluZLEUI';
        this.sanitizeUrl(this.videoUrl);
        this.showTitle = 'THE FRENCH EMBASSY';
        this.showDescription = `We facilitated a smooth streaming process for this panel, bringing together young inspiring voices, to discuss feminism, gender equality, and the rights of sexual minorities, through the lens of decolonial and intersectional approaches.`;
        this.loading = true;
        this.replaceSpotify = false;
        //this.replacedImg = '../../../assets/Pinsent WORK.svg';
        break;

      case 9:
        this.videoUrl =
          'https://open.spotify.com/embed/playlist/2iyPX5nQvPATej1gQzGtL5';
        this.sanitizeUrl(this.videoUrl);
        this.showTitle = 'THE 21 - PODCAST EXPERIMENT';
        this.showDescription = `When South Africa went into lockdown, early 2020, The Good People at Between Productions realised that many of their services would need to be reshaped and transformed to suit an online and digital platform. This led them to take a deep dive into the podcasting medium. In a make-shift home studio for the first 21 days, they produced an episode per day exploring important as well as light conversations about the human experience during a pandemic.`;
        this.loading = true;
        this.replaceSpotify = false;
        //this.replacedImg = '../../../assets/Pinsent WORK.svg';
        break;

      case 10:
        this.videoUrl =
          '//html5-player.libsyn.com/embed/destination/id/2755772/height/450/theme/custom/thumbnail/yes/direction/backward/no-cache/true/render-playlist/yes/custom-color/f5a623/';
        this.sanitizeUrl(this.videoUrl);
        this.showTitle = 'NCCB';
        this.showDescription = `The Good People edit, mix, master, and distribute the weekly preaches that come from the pulpit of New Covenant Church Bryanston.`;
        this.loading = true;
        this.replaceSpotify = false;
        //this.replacedImg = '../../../assets/Pinsent WORK.svg';
        break;

      case 11:
        // this.videoUrl =
        //   '//html5-player.libsyn.com/embed/destination/id/2755772/height/450/theme/custom/thumbnail/yes/direction/backward/no-cache/true/render-playlist/yes/custom-color/f5a623/';
        // this.sanitizeUrl(this.videoUrl);
        this.showTitle = 'HUMAN RIGHTS MEDIA CENTRE';
        this.showDescription = `The Good People have provided sound and voice training to participants all over the world that are interested in the podcast medium.`;
        this.loading = true;
        this.replaceSpotify = true;
        this.replacedImg = '../../../assets/HRMC Work.svg';
        break;

      case 12:
        // this.videoUrl =
        //   '//html5-player.libsyn.com/embed/destination/id/2755772/height/450/theme/custom/thumbnail/yes/direction/backward/no-cache/true/render-playlist/yes/custom-color/f5a623/';
        // this.sanitizeUrl(this.videoUrl);
        this.showTitle = 'AFRICORI';
        this.showDescription = `A boutique label for Africa’s independent artist community, Africori, approached The Good People to design and set up their podcast studio. Located in the heart of Rosebank Johannesburg, the newly renovated space adds to the flare that Africori boasts across international waters with a future-proof space ready for industry leaders to create their podcast stories the right way.`;
        this.loading = true;
        this.replaceSpotify = true;
        this.replacedImg = '../../../assets/Africori WORK.svg';
        break;

      case 13:
        this.videoUrl =
          'https://open.spotify.com/embed/playlist/1J8WAz9vgqCw5OVmEl8xg4';
        this.sanitizeUrl(this.videoUrl);
        this.showTitle = 'SOUND AFRICA - THE AURAL REVOLUTION';
        this.showDescription = `TThe home-grown podcast organisation, Sound Africa, knocked on our door, looking to get vocal training for their podcast hosts. Sound Africa, passionate about transformation on the African continent, has a theatrical tone that shifts radio waves in a big way.`;
        this.loading = true;
        this.replaceSpotify = false;
        //this.replacedImg = '../../../assets/Africori WORK.svg';
        break;

      case 14:
        // this.videoUrl =
        //   'https://open.spotify.com/embed-podcast/show/53LTv4aFaM0nrj8sqaQJU2';
        this.sanitizeUrl(this.videoUrl);
        this.showTitle = 'Pinsent Masons - Construction Law Conference';
        this.showDescription = `We partnered with this renowned law firm to design the staging of their Construction Law conference. We reinforced all of their audio requirements and captured the speakers and panels to turn over audiovisual podcasting packages for their internal distribution.`;
        this.loading = true;
        this.replaceSpotify = true;
        this.replacedImg = '../../../assets/Pinsent WORK.svg';
        break;

      case 15:
        // this.videoUrl =
        //   'https://open.spotify.com/embed-podcast/playlist/53LTv4aFaM0nrj8sqaQJU2';
        this.sanitizeUrl(this.videoUrl);
        this.showTitle = 'Heartveld Vension - Game with a big Heart';
        this.showDescription = `Venison purveyors find their story. While ethically sourcing top grade and delicious venison, The Good People helped accurately portray Heartveld Venison’s story behind farming and distributing practises. We provided consultancy and branded content for their marketing.`;
        this.loading = true;
        this.replaceSpotify = true;
        this.replacedImg = '../../../assets/Heartveld Venison2.svg';
        break;

      case 16:
        // this.videoUrl =
        //   'https://open.spotify.com/embed-podcast/playlist/53LTv4aFaM0nrj8sqaQJU2';
        this.sanitizeUrl(this.videoUrl);
        this.showTitle = 'Paradise Festival - for the love of Rooiberg';
        this.showDescription = `The Good People have been involved in the evolution of a beautiful small town in Limpopo’s story. We ran performance workshops with the youth and partnered in the organisation of an annual music, trail running and community festival.`;
        this.loading = true;
        this.replaceSpotify = true;
        this.replacedImg = '../../../assets/PF WORK.svg';
        break;

      case 17:
        // this.videoUrl =
        //   'https://open.spotify.com/embed-podcast/playlist/53LTv4aFaM0nrj8sqaQJU2';
        this.sanitizeUrl(this.videoUrl);
        this.showTitle = 'Rooiberg Resort';
        this.showDescription = `This family holiday destination is currently undergoing a full rebranding and renovation process. The Good People are providing consultation and branded content creation to get them on the map again - literally speaking.`;
        this.loading = true;
        this.replaceSpotify = true;
        this.replacedImg = '../../../assets/RR WORK.svg';
        break;
      default:
        break;
    }
  }

  sanitizeUrl(url: string): void {
    this.safeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.videoUrl
    );
  }

  closeModal(): void {
    this.collapse = false;
  }
}
