<div class="container">
  <div class="buttons-container">
    <div style="position: relative; width: 35%">
      <div class="logo">
        <img
          class="imglogo"
          src="../../../../assets/Between Productions LOGO.svg"
          alt="between productions logo"
          (click)="toHome()"
        />
      </div>
    </div>

    <div class="menu">
      <h5 class="text" (click)="toAbout()">About</h5>
      <h5 class="text" (click)="toShows()">Work</h5>
      <h5 class="text" (click)="toPricing()">Plans-Pricing</h5>
      <h5 class="text" (click)="toContact()">Contact</h5>
    </div>
  </div>
</div>
