<div class="toolbar">
  <app-toolbar></app-toolbar>
</div>
<div class="container">
  <div class="left-side-container">
    <div class="quadrant-top-left">
      <img
        src="../../../../assets/Between Productions LOGO.svg"
        alt="between product logo"
        class="logo-image"
      />
    </div>
    <div class="quadrant-bottom-left">
      <div class="logo-text-container">
        <h1 class="our-logo-text">Philosophy (Our logo)</h1>
        <p class="our-logo-description-text">
          The <i>Canis Africanis</i> - the pestilential dog of Africa. We have
          observed this ancient dog breed. Often misinterpreted as an endemic
          rather than a magnificent heirloom, this proudly African, highly
          intelligent dog offers true companionship while remaining independent
          and non-burdensome. This breed is a result of natural selection and
          physical and mental adaptation to environmental conditions. Through
          storytelling, The Good People want to be to companies what the Canis
          Africanis has been to Africans for the past 7000 years.
        </p>
      </div>
    </div>
  </div>
  <div class="quadrant-right">
    <div class="about-text-container">
      <h1 class="about-text">About</h1>
      <p class="about-description-text">
        The Good People at Between Productions are allies of the city. We are a
        storytelling agency dedicated to making global connections mean
        something to Johannesburg. Our mission is to implement reformation
        through Story. Whatever your company ethos is, we develop stories to
        iterate and reiterate this - rooting your staff to the cause and
        reaching your market more effectively.
      </p>
      <p class="about-description-text">
        We invite both companies and individuals to think differently with us.
      </p>
      <!-- 
      <p class="about-description-text">
        Between Productions is an ally of creatives, audiovisual engineers,
        scriptwriters, coaches, IT professionals, and administrators. Directed
        by our two founding members, we partner with companies and individuals
        to produce effective and progressive branded content.
      </p> -->
      <p class="about-description-core-values-text">CORE VALUES:</p>
      <ul>
        <li>
          <p class="about-description-text-question">
            <b>THE CITY’S HEARTBEAT:</b> Making JHB great.
          </p>
        </li>
        <li>
          <p class="about-description-text-question">
            <b>BREAKING THE MATRIX:</b> We exist to make alternative thinking a
            go-to.
          </p>
        </li>
        <li>
          <p class="about-description-text-question">
            <b>MEANING MAKING:</b> The work that we do engages people to
            progress society.
          </p>
        </li>
        <li>
          <p class="about-description-text-question">
            <b>KEEPERS OF KINSHIP:</b> We provide a place to belong.
          </p>
        </li>
        <li>
          <p class="about-description-text-question">
            <b>EXTRA-MILE EXECUTION:</b> How you do anything is how you do
            everything.
          </p>
        </li>
      </ul>

      <p class="about-description-core-values-text">HOW WE WORK:</p>
      <p class="about-description-text-question-2">
        We help you identify or unearth your Company Heart. We establish what
        out-the-box services, products, and systems could benefit you. We also
        provide 360-degree service to produce relevant content at our studios.
      </p>
      <p class="about-description-text-question-2">
        We are a group of radical storytellers - each with their unique
        qualifications:
      </p>
      <ul>
        <li>
          <p class="about-description-text-question-2">FACILITATORS</p>
        </li>
        <li>
          <p class="about-description-text-question-2">STRATEGISTS</p>
        </li>
        <li>
          <p class="about-description-text-question-2">DATA ANALYSTS</p>
        </li>
        <li>
          <p class="about-description-text-question-2">IDEATORS</p>
        </li>
        <li>
          <p class="about-description-text-question-2">COPYWRITERS</p>
        </li>
        <li>
          <p class="about-description-text-question-2">EDITORS</p>
        </li>
        <li>
          <p class="about-description-text-question-2">ENGINEERS</p>
        </li>
        <li>
          <p class="about-description-text-question-2">PRODUCERS</p>
        </li>
      </ul>
      <p class="about-description-text">
        We also have an extended network in the film and marketing industries
        with whom we frequently partner.
      </p>
      <p class="about-description-text">
        <i
          >World trends can cause anxiety. E.g., the podcasting medium is
          BUZZING right now. But this doesn't mean that everyone should jump on
          the bandwagon. We don't believe so. So what do your numbers say? What
          is your team saying? What does your community say? We'd rather you be
          a powerful whisper in a world that shouts.</i
        >
      </p>

      <!-- <p class="about-description-text">
        We, The Good People at Between Productions, work with you to get the
        best answers. We help you remove hesitance and avoidance.
      </p>

      <p class="about-description-text">
        We pinpoint the conflict in your narrative and we leverage this to
        ensure increase.
      </p> -->

      <p class="about-description-text-quote">
        <!-- “There is no story that is not true," said Uchendu. "The world has no
        end, and what is good among one people, is an abomination with others.”
        - Chinua Achebe. -->
        If you are moved by our core values and you would like to tell a great
        story today, please <a routerLink="/contact"> <u>get in touch.</u> </a>
      </p>
    </div>
  </div>
</div>
