import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './404NotFound/not-found/not-found.component';
import { AboutComponent } from './About/about/about.component';
import { HomeComponent } from './Home/home/home.component';
import { ShowsComponent } from './Shows/shows/shows.component';
import { ContactComponent } from './Contact/contact/contact.component';
import { PricingComponent } from './Pricing/pricing/pricing.component';
import { LargepriceComponent } from './Largeprice/largeprice/largeprice.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'pricing',
    component: PricingComponent,
  },
  {
    path: 'work',
    component: ShowsComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'large',
    component: LargepriceComponent,
  },
  {
    path: 'notFound',
    component: NotFoundComponent,
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
