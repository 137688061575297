import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toolbarwhite',
  templateUrl: './toolbarwhite.component.html',
  styleUrls: ['./toolbarwhite.component.css'],
})
export class ToolbarwhiteComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  toHome() {
    this.router.navigateByUrl('home');
  }

  toAbout() {
    this.router.navigateByUrl('about');
  }

  toShows() {
    this.router.navigateByUrl('work');
  }

  toPricing() {
    this.router.navigateByUrl('pricing');
  }

  toContact() {
    this.router.navigateByUrl('contact');
  }
}
